<template>
  <div class="app-container">
    <el-dialog title="" :visible.sync="dialogNPAView" width="70%">
      <div v-if="document">
        <NPA :document="document" />
        <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :paginate-elements-by-height="2500"
            :filename="`NPA - ${document.employee_details.name}`"
            :pdf-quality="2"
            :manual-pagination="true"
            pdf-format="legal"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            ref="html2Pdf"
        >
          <section slot="pdf-content">
            <NPA :document="document" />
          </section>
        </vue-html2pdf>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogView = false">Cancel</el-button>
        <el-button type="primary" @click="generateReport">Download</el-button>
        <!-- <el-button v-if="isComplete === '21'" type="success" @click="updateCompletedDocument()">Complete</el-button> -->
      </span>
    </el-dialog>
    <el-dialog title="Approvers" :visible.sync="dialogApproverVisible">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.file_status)">{{ props.row.file_status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span v-if="scope.row.a_date_action" style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 0;">FILE DOCUMENT DASHBOARD</h3>
        <!-- <el-select v-model="isAcknowledged" placeholder="Status">
          <el-option label="Acknowledged" :value="1"></el-option>
          <el-option label="Not Acknowledged" :value="0"></el-option>
        </el-select> -->
      </div>
      <el-row :gutter="24">
        <el-col :span="5" style="float: right; ">
          <el-input
            v-model="nameSearchText"
            size="large"
            @click="hello(scope.row)"
            placeholder="Search for Employee"
          />
        </el-col>
      </el-row>
      <el-table :data="data.filter(data => !nameSearchText || data.em_id_name.toLowerCase().includes(nameSearchText.toLowerCase()))"
          style="width: 100%">
        <el-table-column label="EMPLOYEE" prop="em_id_name"></el-table-column>
        <el-table-column label="DESCRIPTION" prop="npa_bg4_id_name"></el-table-column>
        <el-table-column label="DATE HIRED" prop="datehired">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.datehired) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" prop="mss_a_id_name">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.mss_a_id_name)">{{ props.row.mss_a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="NUMBERS OF APPROVER APPROVED" prop="numbers_of_approved"></el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Create Approval" placement="top-start">
              <el-button v-if="scope.row.mss_a_id === '4'" size="mini" type="success" @click="createApproval(scope.row)"><i class="el-icon-check"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="View Document" placement="top-start">
              <el-button size="mini" type="danger" @click="view(scope.row)"><i class="el-icon-search"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Status" placemnt="top-start">
              <el-button v-if="scope.row.mss_a_id === '21' || scope.row.mss_a_id === '8'" size="mini" type="info" @click="getDocumentStatus(scope.row.id)"><i class="el-icon-edit-outline"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'
import NPA from '@/components/Documents/NPA.vue'

export default {
  components: {
    NPA
  },
  data () {
    return {
      nameSearchText: '',
      dialogView: false,
      dialogNPAView: false,
      isComplete: 0,
      isAcknowledged: 0,
      dialogApproverVisible: false,
      hed_id: 0,
      approvers: [],
      npa: null,
      document: null,
      data: []
    }
  },
  watch: {
    isAcknowledged () {
      this.getFiles()
    }
  },
  mounted () {
    this.getFiles()
  },
  methods: {
    getFiles () {
      this.$http
        .get(`hr.document-list-verification/${this.isAcknowledged}`)
        .then(res => {
          this.data = res.data.body
        })
    },
    createApproval (data) {
      const form = {
        hed_id: data.id,
        bar_id: data.bar_id
      }
      this.$http
        .post('hr.document-create-approver', form)
        .then(() => {
          this.$message({
            type: 'success',
            message: 'Approval created successfully'
          })
          this.getFiles()
        })
    },
    updateCompletedDocument () {
      this.$confirm('Are you sure you want to complete this document?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          const form = {
            id: this.hed_id
          }
          this.$http
            .post('hr.document-update-completed', form)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'Document Completed'
              })
              this.dialogView = false
              this.getFiles()
            })
        })
    },
    view (data) {
      this.isComplete = data.mss_a_id
      this.hed_id = data.id
      this.bar_id = data.bar_id
      this.em_id = data.em_id
      this.$http
        .get(`hr.document-form-data/${data.id}/${data.bar_id}/${data.em_id}`)
        .then(res => {
          this.document = res.data.body
          if (this.document.bar_id === 1) {
            this.dialogNPAView = true
          } else if (this.document.bar_id === 2) {
            this.dialogMPPView = true
          }
        })
    },
    getDocumentStatus (id) {
      this.$http
        .get(`hr.document-approver-list/${id}`)
        .then(res => {
          this.approvers = res.data.body
          this.dialogApproverVisible = true
        })
    },
    getStatusType (type) {
      if (type === 'Waiting' || type === 'Hold' || type === 'For Revision') {
        return 'primary'
      } else if (type === 'Submitted' || type === 'For Signature' || type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      return format(new Date(date), dateFormat)
    }
  }
}
</script>
